import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { 
    selectCurrentUser, 
    selectPaymentCardNumber, 
    selectPaymentRegisterStatus, 
    selectToken } from '../../redux/user/userSelectors';
import { showAlert } from '../../redux/alert/alertActions';
import { showModal } from '../../redux/modal/modalActions';

import Avatar from '../Avatar/Avatar';

//MUI関連のインポート
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ManagePaymentDialog from './ManagePaymentDialog';


const ManagePaymentForm = ({ 
    currentUser, 
    token, 
    showAlert, 
    showModal,
    paymentRegisterStatus,
    paymentCardNumber
 }) => {

    const sealedCardNumber = 
        paymentCardNumber ? 
            "●●●● ●●●● ●●●● " + 
            paymentCardNumber.substring(
                paymentCardNumber.length - 4, 
                paymentCardNumber.length
            )
        :
        null

    const showCardRegisterModal = () => {
        token &&
          showModal(
            {
              options: [
              ],
              title: "お支払方法を登録",
              cancelButton: false,
              children: (
                <ManagePaymentDialog />
              ),
            },
            'OptionsDialog/OptionsDialog'
          );
      };

    return (
        <div >

        {/************************************************ MUI グリッド*************************************************/}
        
            <Grid container style={{marginTop:"15px"}}>
                <Grid  xs={4} style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
                    <Avatar className="avatar--small" imageSrc={currentUser.avatar}/>
                </Grid>
                <Grid  xs={8} style={{display:"flex", alignItems:"center", justifyContent:"start"}}>
                    <div>
                        <h1 className="font-medium" style={{ fontSize: '16px' }}>
                            {currentUser.username}
                        </h1>
                        <p className="font-medium"  style={{ fontSize: '12px' }}>
                            ご登録内容
                        </p>
                    </div>
                </Grid>
            </Grid>

            <Grid container style={{marginTop:"15px"}}>
                <Grid  xs={4} style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
                    <h1 className="font-medium" style={{ fontSize: '14px' }}>
                        お支払い方法
                    </h1>
                </Grid>
                <Grid  xs={6} style={{display:"flex", alignItems:"center", justifyContent:"start"}}>
                    <div>
                        <h1 className="font-medium" style={{ fontSize: '14px' }}>
                            クレジットカード
                        </h1>
                        <p className="font-medium"  style={{ fontSize: '12px', marginTop:"5px", color:"gray" }}>
                            {(paymentRegisterStatus && paymentCardNumber) ? sealedCardNumber : "登録されていません"}
                        </p>
                    </div>         
                </Grid>
                <Grid  xs={2} style={{display:"flex", alignItems:"center", justifyContent:"start"}}>
                    <div>
                        <Button 
                            onClick={() => {showCardRegisterModal()}}
                            variant="outlined"
                            size="large"
                        >
                            {paymentRegisterStatus ? "変更" : "登録"}
                        </Button>
                    </div>         
                </Grid>
            </Grid>

            <Grid container style={{marginTop:"15px"}}>
                <Grid  xs={4} style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
                    <h1 className="font-medium" style={{ fontSize: '14px' }}>
                        ご加入中のプラン
                    </h1>
                </Grid>
                <Grid  xs={8} style={{display:"flex", alignItems:"center", justifyContent:"start"}}>
                    <div>
                        <h1 className="font-medium" style={{ fontSize: '14px' }}>
                            12プラン
                        </h1>
                    </div>         
                </Grid>
            </Grid>

            {/* <Grid container style={{marginTop:"20px"}}>
                <Grid  xs={12} style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
                    <Button variant="outlined" size="large" style={{width:"100%", fontSize:"12px", border:"1.5px solid"}}>ご請求履歴</Button>
                </Grid>
            </Grid> */}

        {/************************************************ MUI グリッド*************************************************/}

        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    showModal: (props, component) => dispatch(showModal(props, component)),
    showAlert: (text, onClick) => dispatch(showAlert(text, onClick)),
});

const mapStateToProps = createStructuredSelector({
    currentUser: selectCurrentUser,
    token: selectToken,
    paymentRegisterStatus: selectPaymentRegisterStatus ,
    paymentCardNumber: selectPaymentCardNumber
});

export default connect(mapStateToProps, mapDispatchToProps)(ManagePaymentForm);