import { formatDistanceStrict, format } from 'date-fns';

export const formatDateDistance = (endDate) => {
  const format = formatDistanceStrict(new Date(), new Date(endDate));
  const duration = format.split(' ');
  duration[1] = duration[1].substring(0, 1);
  if (duration[1] === 's') {
    return '今';
  }
  if (duration[1] === 'm') {
    return duration[0] + '分前';
  }
  if (duration[1] === 'h') {
    return duration[0] + '時間前';
  }
  if (duration[1] === 'd') {
    return duration[0] + '日前';
  }
  if (duration[1] === 'y') {
    return duration[0] + '年前';
  }
  return duration.join(' ');
};

export const formatDate = (date) => {
  date = new Date(date);
  const formattedDate = date.toLocaleDateString('ja-JP', {timeZone: 'Asia/Tokyo'});
  return formattedDate;
};
