import React, { useState } from 'react'

export default function Create() {
    const [preview, setPreview] = useState('');
  
    const handleChangeFile = (e) => {
      const { files } = e.target;
      setPreview(window.URL.createObjectURL(files[0]));
    };
  
    return (
      <div>
        <div className="post-form__preview">
            <img src={preview} />
        </div>
        <input
            type="file"
            name="photo"
            onChange={handleChangeFile}
        />
      </div>
    );
}