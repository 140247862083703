const notificationTypes = {
  ADD_NOTIFICATION: 'ADD_NOTIFICATION',
  FETCH_NOTIFICATIONS_START: 'FETCH_NOTIFICATIONS_START',
  FETCH_NOTIFICATIONS_SUCCESS: 'FETCH_NOTIFICATIONS_SUCCESS',
  FETCH_NOTIFICATIONS_FAILURE: 'FETCH_NOTIFICATIONS_FAILURE',
  READ_NOTIFICATIONS: 'READ_NOTIFICATIONS',
  CLEAR_NOTIFICATIONS: 'CLEAR_NOTIFICATIONS',
};

export default notificationTypes;
