import { Button, CircularProgress, Icon, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { 
    selectCurrentUser, 
    selectPaymentCardCvc, 
    selectPaymentCardExpDate, 
    selectPaymentCardExpMonth, 
    selectPaymentCardExpYear, 
    selectPaymentCardHolderName, 
    selectPaymentCardNumber, 
    selectPaymentRegisterStatus, 
 } from '../../redux/user/userSelectors';
import { updatePayment } from '../../redux/user/userActions';
import { createStructuredSelector } from 'reselect';
import { connect } from "react-redux"
import { Visa } from "react-pay-icons";
import { Mastercard } from "react-pay-icons";
import { Jcb } from "react-pay-icons";
import { DinersClub } from "react-pay-icons";
import { Amex } from "react-pay-icons";
import { useHistory } from 'react-router-dom';
import { hideModal, showModal } from '../../redux/modal/modalActions';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';

//注意：このモーダル上のTextFieldではValidation実施していない
const ManagePaymentDialog = ({ 
    currentUser, 
    paymentCardNumber,
    paymentCardExpMonth,
    paymentCardExpYear,
    paymentCardHolderName,
    paymentCardCvc,
    paymentRegisterStatus, 
    updatePayment, //Actionsからの関数
    showModal, //Actionsからの関数
    hideModal //Actionsからの関数
}) => {

    const history = useHistory();

    //注意：あくまでアルファ版なのでセキュリティ性無視してここでState用意してDBへ入れる（更新されることを考えてreduxは使わない）
    const [cardNumber,     setCardNumber]     = useState(paymentCardNumber);
    const [cardExpMonth,   setCardExpMonth]   = useState(paymentCardExpMonth);
    const [cardExpYear,    setCardExpYear]    = useState(paymentCardExpYear);
    const [cardHolderName, setCardHolderName] = useState(paymentCardHolderName);
    const [cardCvc,        setCardCvc]        = useState(paymentCardCvc);

    const [registeringCard, setRegisteringCard] = useState(false);

    useEffect(() => {
        if (registeringCard) { //処理プロセスを疑似的に見せるために2秒タイムアウトしてる
            setTimeout(() => {
                setRegisteringCard(false)
                hideModal('OptionsDialog/OptionsDialog');
                showModal(
                    {
                      options: [
                      ],
                      cancelButton: false,
                      children: (
                        <div>
                            <div style={{display:"flex", alignItems:"center", justifyContent:"end", marginTop:"20px", marginRight:"20px" }}>
                                <CloseIcon sx={{ fontSize: 24 }} onClick={() => {hideModal('OptionsDialog/OptionsDialog')}}/>
                            </div>
                            <div style={{display:"flex", justifyContent:"center", height:"300px", marginTop:"10px"}}>
                                <div >
                                    <p style={{fontSize:"18px", margin:"20px"}}>カード情報が登録されました。</p>
                                    <CheckCircleOutlineIcon sx={{ fontSize: 100 }} color="success" />
                                    <p style={{fontSize:"18px", margin:"20px"}}>コンテンツをお楽しみください！</p>
                                </div>
                            </div>
                        </div>
                        
                      ),
                    },
                    'OptionsDialog/OptionsDialog'
                  );
            }, 2000);
        }
    }, [registeringCard])

    return (
        <div >
            <div>
                <div style={{display:"flex", alignItems:"center", justifyContent:"center", marginTop:"10px"}}>
                    <div style={{display:"flex", alignItems:"center", justifyContent:"space-between", width:"90%"}}>
                        <p>
                            カード番号
                        </p>
                        <div style={{display:"flex"}}>
                            <Visa style={{ marginLeft:"5px", width: "30px" }} />
                            <Mastercard style={{ marginLeft:"5px", width: "30px" }} />
                            <Jcb style={{ marginLeft:"5px", width: "30px" }} />
                            <Amex style={{ marginLeft:"5px", width: "30px" }} />
                            <DinersClub style={{ marginLeft:"5px", width: "30px" }} />
                        </div>
                    </div>
                </div>
                <TextField 
                    disabled = {registeringCard}
                    style = {{width:"90%", marginTop:"5px"}}
                    placeholder="入力してください" variant="outlined" 
                    value = {cardNumber}
                    onChange={(e) => {
                        setCardNumber(e.target.value)
                    }}
                    inputProps={{ maxLength: 16 }}
                />
                
            </div>
            <div>
                <div style={{display:"flex", alignItems:"center", justifyContent:"center", marginTop:"10px"}}>
                    <div style={{display:"flex", alignItems:"center", justifyContent:"space-between", width:"90%"}}>
                        <p>
                            有効期限
                        </p>
                        <p>
                            
                        </p>
                    </div>
                </div>
                <div style={{display:"flex", alignItems:"center", justifyContent:"center", marginTop:"10px"}}>
                    <div style={{display:"flex", alignItems:"center", justifyContent:"space-between", width:"90%"}}>
                        <TextField 
                            disabled = {registeringCard}
                            style = {{width:"45%", marginTop:"5px"}}
                            placeholder="MM" variant="outlined" 
                            value = {cardExpMonth}
                            inputProps={{ maxLength: 2 }}
                            onChange={(e) => {
                                setCardExpMonth(e.target.value)
                            }}
                        />
                        <p>
                            /
                        </p>
                        <TextField 
                            disabled = {registeringCard}
                            style = {{width:"45%", marginTop:"5px"}}
                            placeholder="YY" variant="outlined" 
                            value = {cardExpYear}
                            inputProps={{ maxLength: 2 }}
                            onChange={(e) => {
                                setCardExpYear(e.target.value)
                            }}
                        />
                    </div>
                </div>
            </div>
            <div>
                <div style={{display:"flex", alignItems:"center", justifyContent:"center", marginTop:"10px"}}>
                    <div style={{display:"flex", alignItems:"center", justifyContent:"space-between", width:"90%"}}>
                        <p>
                            カード名義
                        </p>
                        <p>
                            
                        </p>
                    </div>
                </div>
                <TextField 
                    disabled = {registeringCard}
                    style = {{width:"90%", marginTop:"5px"}}
                    placeholder="Fan田 Tok郎" variant="outlined" 
                    value = {cardHolderName}
                    onChange={(e) => {
                        setCardHolderName(e.target.value)
                    }}
                    
                />
            </div>
            <div>
                <div style={{display:"flex", alignItems:"center", justifyContent:"center", marginTop:"10px"}}>
                    <div style={{display:"flex", alignItems:"center", justifyContent:"space-between", width:"90%"}}>
                        <p>
                            セキュリティコード
                        </p>
                        <p>
                            
                        </p>
                    </div>
                </div>
                <TextField 
                    disabled = {registeringCard}
                    style = {{width:"90%", marginTop:"5px"}}
                    placeholder="CVC" variant="outlined" 
                    value={cardCvc}
                    type="password"
                    inputProps={{
                        autocomplete: 'new-password',
                        form: {
                          autocomplete: 'off',
                        },
                        maxLength:4
                    }}
                    onChange={(e) => {
                        setCardCvc(e.target.value)
                    }}
                />
            </div>
            <div>
                <div style={{display:"flex", alignItems:"center", justifyContent:"center", marginTop:"10px"}}>
                    <div style={{width:"90%",textAlign: "left"}}>
                        <p>
                            ※ クレジットカードの明細書には「FanCha」もしくは「XXXX」等の名称が記載されます。
                        </p>
                        <p>
                            ※ コンビニ等で発行可能なVプリカ等の審査が不要のクレジットカード会社が発行しているプリペイドカードでのお支払いも可能です。
                        </p>
                    </div>
                </div>
            </div>

            <div style={{display:"flex", alignItems:"center", justifyContent:"center", marginTop:"20px", marginBottom:"20px"}}>
                
                {registeringCard ? 
                <Button
                    variant="contained"
                    size="large"
                    color= "success"
                >
                    
                    <p>
                        カードを登録中です..
                    </p>
                </Button>
                :
                <Button 
                    disabled = {!cardNumber || !cardExpMonth || !cardExpYear || !cardHolderName || !cardCvc}
                    onClick={(event) => {
                        updatePayment( cardNumber, cardExpMonth, cardExpYear, cardHolderName, cardCvc );
                        setRegisteringCard(true)
                    }}
                    variant="contained"
                    size="large"
                    wid
                >
                    {(paymentRegisterStatus && paymentCardNumber) ? "カード情報を変更する" : "カード情報を登録する"}
                </Button>
                }  
            </div>
        </div>

    )
}

const mapDispatchToProps = (dispatch) => ({
    updatePayment: ( cardNumber, cardExpMonth, cardExpYear, cardHolderName, cardCvc ) =>
        dispatch(updatePayment( cardNumber, cardExpMonth, cardExpYear, cardHolderName, cardCvc )),
    hideModal: (component) => dispatch(hideModal(component)),
    showModal: (props, component) => dispatch(showModal(props, component)),
});

const mapStateToProps = createStructuredSelector({
    currentUser: selectCurrentUser,
    paymentRegisterStatus: selectPaymentRegisterStatus,
    paymentCardNumber: selectPaymentCardNumber,
    paymentCardExpMonth: selectPaymentCardExpMonth,
    paymentCardExpYear: selectPaymentCardExpYear,
    paymentCardHolderName: selectPaymentCardHolderName,
    paymentCardCvc: selectPaymentCardCvc
});



export default connect(mapStateToProps, mapDispatchToProps)(ManagePaymentDialog);