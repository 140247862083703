import React from 'react';
import PropTypes from 'prop-types';
import { useTransition, animated } from 'react-spring';
import { connect } from 'react-redux';
import { hideModal, showModal } from '../../redux/modal/modalActions';
import TextButton from '../Button/TextButton/TextButton';
import { nekoPurchase } from '../../redux/user/userActions';
import { createStructuredSelector } from 'reselect';
import { selectPaymentRegisterStatus } from '../../redux/user/userSelectors';
import ManagePaymentDialog from '../ManagePaymentForm/ManagePaymentDialog';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import CloseIcon from '@mui/icons-material/Close';

const NekoSubscriptPaidDialog = ({
  hide,
  showModal,
  hideModal,
  title,
  nekoPurchase,
  cancelButton = true,
  paymentRegisterStatus
}) => {
  const transitions = useTransition(true, null, {
    from: { transform: 'scale(1.2)', opacity: 0.5 },
    enter: { transform: 'scale(1)', opacity: 1 },
    leave: { opacity: 0 },
    config: {
      mass: 1,
      tension: 500,
      friction: 30,
    },
  });

  return transitions.map(({ item, key, props }) => (
    <animated.div style={props} key={key} className="neko-dialog">
      {title && (
        <header className="neko-dialog__title">
          <h1 className="heading-3">{title}</h1>
          {!cancelButton && (
            <TextButton style={{ fontSize: '3rem' }} onClick={() => hide()}>
              &#10005;
            </TextButton>
          )}
        </header>
      )}
      <div class="ant-modal-content">
        <div class="ant-modal-body">
          <div class="css-1dbjc4n">
            <div dir="auto" class="css-901oao css-901oao-dark">※ DMが開放されてはる(23)♂️かな(18)♀️couple様とやり取りができるようになります。</div>
            <div dir="auto" class="css-901oao css-901oao-left">次回の更新はプラン購入一ヶ月後の同日に引き落としされます。</div>
            <a href="https://myfanshelp.zendesk.com/hc/ja/categories/4404363332239" target="brank">
              <div dir="auto" class="css-901oao css-901oao-link-text">▶ 購入等に関するヘルプはこちら</div>
            </a>
          </div>
        </div>
      </div>
      <div class="ant-modal-footer">
        <button type="button" class="ant-btn" onClick={() => hide()}>
          <span>いいえ</span>
        </button>
        <button type="button" class="ant-btn ant-btn-primary"
          onClick={() => {
            if (!paymentRegisterStatus) {
              showModal(
                {
                  options: [
                  ],
                  title: "お支払方法を登録",
                  cancelButton: false,
                  children: (
                    <ManagePaymentDialog />
                  ),
                },
                'OptionsDialog/OptionsDialog'
              );
            } else {
              nekoPurchase();
              // showModal(
              //   {
              //     title: "購入しました",
              //     options: [
              //       {
              //         warning: true,
              //         text: '閉じる',
              //         onClick: () => hide(),
              //       },
              //     ],
              //     cancelButton: false
              //   },
              //   'OptionsDialog/OptionsDialog'
              // )
              showModal(
                {
                  options: [
                  ],
                  cancelButton: false,
                  children: (
                    <div>
                        <div style={{display:"flex", alignItems:"center", justifyContent:"end", marginTop:"20px", marginRight:"20px" }}>
                            <CloseIcon sx={{ fontSize: 24 }} onClick={() => {hideModal('OptionsDialog/OptionsDialog'); hide()}}/>
                        </div>
                        <div style={{display:"flex", justifyContent:"center", height:"300px", marginTop:"10px"}}>
                            <div >
                                <p style={{fontSize:"18px", margin:"20px"}}>プランが購入されました。</p>
                                <LockOpenIcon sx={{ fontSize: 100 }} color="success" />
                                <p style={{fontSize:"18px", margin:"20px"}}>コンテンツをお楽しみください！</p>
                            </div>
                        </div>
                    </div>
                    
                  ),
                },
                'OptionsDialog/OptionsDialog'
              );
            }
          }}
        >
          <span>はい</span>
        </button>
      </div>
      {cancelButton && (
        <button
          className="options-dialog__button"
          onClick={(event) => {
            event.nativeEvent.stopImmediatePropagation();
            hide();
          }}
        >
          Cancel
        </button>
      )}
    </animated.div>
  ));
};

NekoSubscriptPaidDialog.propTypes = {
  hide: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
};

const mapStateToProps = createStructuredSelector({
  paymentRegisterStatus: selectPaymentRegisterStatus,
});

const mapDispatchToProps = (dispatch) => ({
  showModal: (props, component) => dispatch(showModal(props, component)),
  nekoPurchase: () => dispatch(nekoPurchase()),
  hideModal: (component) => dispatch(hideModal(component)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NekoSubscriptPaidDialog);