import { connect } from 'react-redux';
import { showModal } from '../../redux/modal/modalActions';
import { confirmR18 } from '../../redux/user/userActions';
import ConfirmR18DialogPrompt from './ConfirmR18DialogPrompt';
import React from 'react';
import { createStructuredSelector } from 'reselect';
import { selectToken, selectR18Confirmed } from '../../redux/user/userSelectors';

const ConfirmR18 = ({
  authToken,
  r18,
  showModal,
  confirmR18
}) => {
  if (!authToken && !r18) {
    showModal(
      {
        children: (
          <ConfirmR18DialogPrompt />
        ),
        options: [
          {
            warning: true,
            text: 'はい、私は18歳以上です',
            onClick: () => confirmR18(),
          },
          {
            warning: false,
            text: 'いいえ、退出します',
            onClick: () => window.location.href = "https://www.yahoo.co.jp/",
          },
        ],
        cancelButton: false
      },
      'OptionsDialog/OptionsDialog'
    )
  }

  return () => { };
};

const mapStateToProps = createStructuredSelector({
  authToken: selectToken,
  r18: selectR18Confirmed,
});

const mapDispatchToProps = (dispatch) => ({
  showModal: (props, component) => dispatch(showModal(props, component)),
  confirmR18: () => dispatch(confirmR18())
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmR18);
