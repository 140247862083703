import React from 'react';

const ConfirmR18DialogPrompt = () => (
  <div className="r18-confirm-dialog-prompt">
    <div style={{marginBottom:"10px"}}>
      年齢の確認
    </div>
    <div>
      <p style={{fontSize:"12px", marginBottom:"10px"}} >ここから先は、成人向け（アダルト）コンテンツを含むページとなります。成人向けの画像、映像が含まれます。18歳未満の物が閲覧することは禁止します。</p>
      <p style={{fontWeight:"bold", marginBottom:"10px"}}>あなたは18歳以上ですか？</p>
      <p style={{fontSize:"12px", color:"red"}}>(自身の居住国や地域社会における規約・法律に従ってください)</p>
      {/* <p style={{fontSize:"12px"}}>以下「はい」のボタンをクリックすると
        <a href="" target="blank">利用規約</a>、
        <a href="" target="blank">プライバシーポリシー</a>に同意したものとみなされます。
      </p> */}
    </div>
  </div>
);

export default ConfirmR18DialogPrompt;
