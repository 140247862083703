import { createSelector } from 'reselect';

const selectUser = (state) => state.user;

export const selectCurrentUser = createSelector(
  [selectUser],
  (user) => user.currentUser
);

export const selectError = createSelector([selectUser], (user) => user.error);

export const selectToken = createSelector([selectUser], (user) => user.token);

export const selectFetching = createSelector(
  [selectUser],
  (user) => user.fetching
);

export const selectFetchingAvatar = createSelector(
  [selectUser],
  (user) => user.fetchingAvatar
);

export const selectUpdatingProfile = createSelector(
  [selectUser],
  (user) => user.updatingProfile
);

export const selectPaymentRegisterStatus = createSelector(
  [selectUser],
  (user) => user.paymentRegisterStatus
);

export const selectPaymentCardNumber = createSelector(
  [selectUser],
  (user) => user.paymentCardNumber
);

export const selectPaymentCardExpMonth = createSelector(
  [selectUser],
  (user) => user.paymentCardExpMonth
);

export const selectPaymentCardExpYear = createSelector(
  [selectUser],
  (user) => user.paymentCardExpYear
);

export const selectPaymentCardHolderName = createSelector(
  [selectUser],
  (user) => user.paymentCardHolderName
);

export const selectPaymentCardCvc = createSelector(
  [selectUser],
  (user) => user.paymentCardCvc
);

export const selectR18Confirmed = createSelector(
  [selectUser],
  (user) => user.r18
);

export const selectNekoPurchased = createSelector(
  [selectUser],
  (user) => user.neko_purchased
);