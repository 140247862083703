export const validateEmail = (email) => {
  if (
    !email ||
    !email.match(
      //eslint-disable-next-line
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
  ) {
    return '有効なメールアドレスを入力してください。';
  }
  return false;
};

export const validateFullName = (fullName) => {
  if (!fullName) {
    return '有効な名前を入力してください。';
  }
  return false;
};

export const validateUsername = (username) => {
  if (!username) {
    return 'ユーザー名を入力してください。';
  } else if (username.length > 30 || username.length < 3) {
    return 'ユーザー名は3文字から30文字の間で入力してください。';
    //eslint-disable-next-line
  } else if (!username.match(/^[a-zA-Z0-9\_.]+$/)) {
    return 'ユーザー名にはa ～ Zの文字0 ～ 9の数字および記号 _ のみを含めることができます。';
  }
  return false;
};

export const validatePassword = (password) => {
  if (!password) {
    return '有効なパスワードを入力してください。';
  } else if (password.length < 6) {
    return 'セキュリティ上の理由から、パスワードは 6 文字以上にする必要があります。';
  } else if (
    !password.match(/^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{6,}$/)
  ) {
    return 'パスワードには、少なくとも 1 つの大文字、1 つの小文字、1 つの特殊文字、および 1 つの数字が含まれている必要があります。';
  }
  return false;
};

export const validateBio = (bio) => {
  if (bio.length > 130) {
    return 'Your bio has to be 120 characters or less.';
  }
  return false;
};

export const validateWebsite = (website) => {
  if (
    website &&
    !website.match(
      //eslint-disable-next-line
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
    )
  ) {
    return 'Please provide a valid website.';
  }
  return false;
};
